<template>
  <div class="memberList" v-loading="loading">
    <Search
      :formData="formData"
      placeholderText="成员名称/负责人/联系电话"
      @handleQuery="handleQuery"
    >
      <template slot="left">
        <SelectArea
          checkStrictly
          size="small"
          class="area"
          placeholder="地区筛选"
          @change="areaChange"
        />
        <el-select
          size="small"
          class="m-l-8"
          v-if="isManager"
          v-model="formData.status"
          placeholder="状态筛选"
        >
          <el-option
            v-for="item in statusOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </template>
    </Search>

    <el-table
      v-show="isManager"
      ref="table"
      class="table-base mt-8"
      size="small"
      :data="tableData"
      stripe
      border
      :default-sort="{ prop: 'joinTime', order: 'descending' }"
      style="width: 100%"
      height="400px"
      @sort-change="sortChange"
      @selection-change="handleSelectionChange"
    >
      <el-table-column
        type="selection"
        align="center"
        width="55"
        :selectable="selectableFn"
      >
      </el-table-column>
      <el-table-column
        label="成员名称"
        prop="name"
        min-width="180"
        align="center"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        label="所在地区"
        prop="area"
        min-width="100"
        align="center"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        label="负责人"
        prop="contacts"
        min-width="100"
        align="center"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          <span>{{ scope.row.contacts }}/{{ scope.row.phone }}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="成员状态"
        prop="status"
        min-width="100"
        align="center"
        sortable
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          <el-switch
            v-model="scope.row.memberStatus"
            active-color="#13ce66"
            inactive-color="#dcdfe6"
            @change="statusChange(scope.row)"
          >
          </el-switch>
        </template>
      </el-table-column>
      <el-table-column
        label="加入时间"
        prop="joinTime"
        min-width="120"
        align="center"
        sortable
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          <span>
            {{ $dayjs(scope.row.joinTime).format("YYYY-MM-DD HH:mm:ss") }}
          </span>
        </template>
      </el-table-column>
      <el-table-column fixed="right" label="操作" align="center" width="150">
        <template slot-scope="scope">
          <el-button
            type="text"
            size="mini"
            class="text-danger"
            :disabled="scope.row.isOwner"
            @click="handleExit(scope.row)"
          >
            踢出联盟
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-table
      v-show="!isManager"
      ref="table"
      class="table-base mt-8"
      size="small"
      :data="tableData"
      stripe
      border
      :default-sort="{ prop: 'joinTime', order: 'descending' }"
      style="width: 100%"
      height="400px"
      @sort-change="sortChange"
      @selection-change="handleSelectionChange"
    >
      <el-table-column
        label="成员名称"
        prop="name"
        min-width="180"
        align="center"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        label="所在地区"
        prop="area"
        min-width="100"
        align="center"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        label="负责人"
        prop="contacts"
        min-width="100"
        align="center"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          <span>{{ scope.row.contacts }}/{{ scope.row.phone }}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="加入时间"
        prop="joinTime"
        min-width="120"
        align="center"
        sortable
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          <span>
            {{ $dayjs(scope.row.joinTime).format("YYYY-MM-DD HH:mm:ss") }}
          </span>
        </template>
      </el-table-column>
    </el-table>
    <div class="flex justify-between items-center mt-8">
      <el-button
        plain
        size="small"
        v-if="isManager"
        :disabled="!this.multipleSelection.length"
        @click="handleBatchExit"
      >
        批量踢出
      </el-button>
      <span v-else></span>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        background
        :current-page="pageData.page"
        :page-sizes="pageData.pageSizes"
        :page-size="pageData.pageSize"
        :pager-count="pageData.pagerCount"
        layout="total, sizes, prev, pager, next, jumper"
        :total="pageData.total"
      >
      </el-pagination>
    </div>
    <!-- 解散、退出弹框 -->
    <TipsBox ref="tips" :tipsObj="tipsObj" @sure="handleSure" />
  </div>
</template>

<script>
import Search from "../../components/Search.vue";
import SelectArea from "@/components/selectArea/SelectArea.vue";
import TipsBox from "../../components/TipsBox.vue";
import {
  getAllianceMembersList, //联盟成员列表
  deleteAllianceMembers, //踢出联盟成员
  disableAlliance, //停用
  enableAlliance, //启用
} from "@/api/alliance";

const defaultFormData = {
  keyword: "", // 关键字
  areaId: 0,
};
export default {
  name: "MemberList",
  components: {
    Search,
    SelectArea,
    TipsBox,
  },
  props: {
    isOwner: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
      formData: JSON.parse(JSON.stringify(defaultFormData)),
      tableData: [],
      pageData: {
        page: 1,
        pageSizes: [10, 20, 50, 100],
        pageSize: 10,
        total: 0,
        pagerCount: 5,
      },
      multipleSelection: [],
      sort: "joinTime-desc",
      statusOptions: [
        {
          value: "normal",
          label: "启用",
        },
        {
          value: "disabled",
          label: "禁用",
        },
      ],
      tipsObj: {},
      curRow: {},
      isManager: true,
    };
  },
  watch: {
    isOwner: {
      handler(val) {
        this.isManager = val;
      },
      immediate: true,
    },
  },
  mounted() {
    // setTimeout(() => {
    //   this.$nextTick(() => {
    this.getList();
    //   });
    // }, 2000);
  },
  methods: {
    selectableFn(row) {
      return !row.isOwner;
    },
    handleSure(type) {
      if (type == 3) {
        let selectArr = [];
        if (Object.keys(this.curRow).length) {
          selectArr.push(this.curRow.id);
        }
        selectArr = [...selectArr, ...this.multipleSelection];
        selectArr = Array.from(new Set(selectArr));

        let params = {
          id: this.$route.query.id,
          ids: selectArr,
        };

        deleteAllianceMembers(params).then(() => {
          this.$message.success("踢出成功");
          this.handleCurrentChange(1);
          this.$refs.tips.close();
        });
      }
    },
    //批量踢出联盟
    handleBatchExit() {
      console.log("批量踢出", this.multipleSelection);
      if (!this.multipleSelection.length) return;
      this.tipsObj = {
        title: "踢出联盟提示",
        type: 3,
        showIcon: true,
        tipsHead: "是否确认将选中成员踢出联盟？",
        tipsContent: `是否确认将选中成员踢出联盟，踢出成功后联盟成员将不能在此联盟进行商品询价、交易等操作，请谨慎操作！`,
      };
      this.$refs.tips.show();
    },
    //踢出联盟
    handleExit(row) {
      console.log("踢出联盟", row);
      this.curRow = { ...row };
      this.tipsObj = {
        title: "踢出联盟提示",
        type: 3,
        showIcon: true,
        tipsHead: "是否确认将选中成员踢出联盟？",
        tipsContent: `是否确认将选中成员踢出联盟，踢出成功后联盟成员将不能在此联盟进行商品询价、交易等操作，请谨慎操作！`,
      };
      this.$refs.tips.show();
    },
    //成员状态
    statusChange(row) {
      if (row.status == "normal") {
        disableAlliance({ id: row.id }).then(() => {
          this.$message.success("停用成功");
          this.getList();
        });
      } else {
        enableAlliance({ id: row.id }).then(() => {
          this.$message.success("启用成功");
          this.getList();
        });
      }
    },
    handleQuery() {
      this.pageData.page = 1;
      this.getList();
    },
    areaChange(areaObj) {
      this.formData.areaId = areaObj.areaId;
      this.handleQuery();
    },
    handleSelectionChange(val) {
      this.multipleSelection = val.map((item) => item.id);
    },
    sortChange(sort) {
      if (!sort.order) return;
      let order = "";
      if (sort.order === "descending") {
        order = "desc";
      }
      if (sort.order === "ascending") {
        order = "asc";
      }
      this.sort = sort.prop + "-" + order;
      this.getList();
    },
    handleSizeChange(val) {
      this.pageData.page = 1;
      this.pageData.pageSize = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.pageData.page = val;
      this.getList();
    },
    getList() {
      this.loading = true;
      const params = {
        id: this.$route.query.id,
        ...this.formData,
        page: this.pageData.page,
        pageSize: this.pageData.pageSize,
        sort: this.sort,
      };

      getAllianceMembersList(params).then((res) => {
        this.pageData.total = res?.totalSize || 0;
        let tableData = res?.rows || [];
        if (tableData.length) {
          tableData.forEach((item) => {
            item.memberStatus = item.status == "normal" ? true : false;
          });
        }
        this.tableData = [...tableData];
        this.loading = false;
      });
    },
  },
};
</script>

<style lang="less" scoped>
.memberList {
  height: 100%;
  display: flex;
  flex-direction: column;
  .area {
    width: 200px !important;
  }
  .text-danger:hover,
  .text-danger:active,
  .text-danger:focus {
    color: #f56c6ca6;
  }
  .is-disabled:hover,
  .is-disabled:active,
  .is-disabled:focus {
    color: #c0c4cc;
  }
}
</style>
