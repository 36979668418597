<template>
  <el-dialog
    title="联盟规范"
    class="allianceNorm"
    :visible.sync="dialogVisible"
    append-to-body
    :close-on-click-modal="false"
    top="2vh"
    width="1020px"
  >
    <div class="content">
      <AllianceInfoEdit
        :allianceObj="allianceObj"
        @change="allianceObjChange"
      />
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button size="small" @click="dialogVisible = false">取 消</el-button>
      <el-button size="small" type="primary" @click="handleSumbit">
        确 定
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
import AllianceInfoEdit from "./AllianceInfoEdit.vue";
export default {
  name: "AllianceNormBox",
  components: {
    AllianceInfoEdit,
  },
  props: {
    allianceObj: {
      type: Object,
      require: true,
    },
  },
  data() {
    return {
      dialogVisible: false,
      allianceInfo: {},
    };
  },
  methods: {
    handleSumbit() {
      this.$emit("sure", this.allianceInfo);
    },
    allianceObjChange(val) {
      this.allianceInfo = { ...val };
    },
    show() {
      this.dialogVisible = true;
    },
    close() {
      this.dialogVisible = false;
    },
  },
};
</script>

<style lang="less" scoped>
.allianceNorm {
  /deep/.allianceObj {
    padding: 0;
  }
  /deep/.el-dialog__footer {
    text-align: right;
  }
}
</style>
