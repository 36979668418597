<template>
  <div class="allianceNorm">
    <AllianceInfo type="norm" :allianceInfo="allianceInfo" />
  </div>
</template>

<script>
import AllianceInfo from "../../components/AllianceInfo.vue";

export default {
  name: "AllianceNorm",
  components: {
    AllianceInfo,
  },
  props: {
    allianceObj: {
      type: Object,
    },
  },
  data() {
    return {
      allianceInfo: {},
    };
  },
  watch: {
    allianceObj: {
      handler(val) {
        this.allianceInfo = { ...val };
      },
      immediate: true,
      deep: true,
    },
  },

};
</script>

<style lang="less" scoped>
.allianceNorm {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
}
</style>
