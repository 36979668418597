<template>
  <div class="allianceLogs" v-loading="loading">
    <Search
      :formData="formData"
      placeholderText="成员名称/操作人/联系电话"
      @handleQuery="handleQuery"
    >
      <template slot="left">
        <SelectArea
          checkStrictly
          size="small"
          class="area"
          placeholder="地区筛选"
          @change="areaChange"
        />
        <el-select
          size="small"
          class="m-l-8"
          v-model="formData.type"
          placeholder="日志类型筛选"
        >
          <el-option
            v-for="item in typeOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </template>
    </Search>

    <el-table
      ref="table"
      class="table-base mt-8"
      size="small"
      :data="tableData"
      stripe
      border
      :default-sort="{ prop: 'createdAt', order: 'descending' }"
      style="width: 100%"
      height="400px"
      @sort-change="sortChange"
    >
      <el-table-column
        label="成员名称"
        prop="name"
        min-width="180"
        align="center"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          <span>{{ scope.row.member.name }}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="所在地区"
        prop="area"
        min-width="100"
        align="center"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          <span>{{ scope.row.member.area }}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="操作人"
        prop="operator"
        min-width="100"
        align="center"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          <span
            >{{ scope.row.createdByName }}/{{ scope.row.createdByPhone }}</span
          >
        </template>
      </el-table-column>
      <el-table-column
        label="日志类型"
        prop="typeName"
        min-width="100"
        align="center"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
        label="操作时间"
        prop="createdAt"
        min-width="120"
        align="center"
        sortable
        show-overflow-tooltip
      ></el-table-column>
    </el-table>
    <div class="flex justify-between items-center mt-8">
      <span></span>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        background
        :current-page="pageData.page"
        :page-sizes="pageData.pageSizes"
        :page-size="pageData.pageSize"
        :pager-count="pageData.pagerCount"
        layout="total, sizes, prev, pager, next, jumper"
        :total="pageData.total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import Search from "../../components/Search.vue";
import SelectArea from "@/components/selectArea/SelectArea.vue";
import {
  getAllianceLogs, //日志列表
} from "@/api/alliance";

const defaultFormData = {
  keyword: "", // 关键字
  areaId: 0,
};
export default {
  name: "AllianceLogs",
  components: {
    Search,
    SelectArea,
  },
  data() {
    return {
      loading: false,
      formData: JSON.parse(JSON.stringify(defaultFormData)),
      tableData: [],
      pageData: {
        page: 1,
        pageSizes: [10, 20, 50, 100],
        pageSize: 10,
        total: 0,
        pagerCount: 5,
      },
      sort: "createdAt-desc",
      typeOptions: [
        {
          value: "加入联盟",
          label: "加入联盟",
        },
        {
          value: "退出联盟",
          label: "退出联盟",
        },
        {
          value: "管理员移交",
          label: "管理员移交",
        },
        {
          value: "成员禁用",
          label: "成员禁用",
        },
        {
          value: "成员启用",
          label: "成员启用",
        },
      ],
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    handleQuery() {
      this.pageData.page = 1;
      this.getList();
    },
    areaChange(areaObj) {
      this.formData.areaId = areaObj.areaId;
      this.handleQuery();
    },
    sortChange(sort) {
      if (!sort.order) return;
      let order = "";
      if (sort.order === "descending") {
        order = "desc";
      }
      if (sort.order === "ascending") {
        order = "asc";
      }
      this.sort = sort.prop + "-" + order;
      this.getList();
    },
    handleSizeChange(val) {
      this.pageData.page = 1;
      this.pageData.pageSize = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.pageData.page = val;
      this.getList();
    },
    getList() {
      this.loading = true;
      const params = {
        ...this.formData,
        id: this.$route.query.id,
        page: this.pageData.page,
        pageSize: this.pageData.pageSize,
        sort: this.sort,
      };
      getAllianceLogs(params).then((res) => {
        this.tableData = res.rows || [];
        this.pageData.total = res?.totalSize || 0;
        this.loading = false;
      });
    },
  },
};
</script>

<style lang="less" scoped>
.allianceLogs {
  height: 100%;
  display: flex;
  flex-direction: column;
  .area {
    width: 200px !important;
  }
}
</style>
