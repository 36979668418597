<template>
  <div class="detail">
    <div class="head">联盟详情</div>
    <div class="content flex flex-col">
      <div class="flex p-8">
        <div class="logo">
          <img v-if="allianceInfo.logoUrl" :src="allianceInfo.logoUrl" />
          <span v-else>联盟图标</span>
        </div>
        <div class="mx-16 mt-4 info">
          <div class="font-bold text-lg">{{ allianceInfo.name }}</div>
          <div class="text-sm mt-8">所在地区：{{ allianceInfo.area }}</div>
          <div class="text-sm mt-8">
            联盟管理员：
            {{ allianceInfo.contacts || "-" }}/{{ allianceInfo.phone || "-" }}
          </div>
          <div class="text-sm mt-8">创建时间：{{ allianceInfo.createdAt }}</div>
        </div>
        <div class="flex-1 p-8">
          <div class="flex">
            <div class="item">
              <div class="label">价格展示</div>
              <div class="value">
                {{ allianceInfo.priceTypeName }}
              </div>
            </div>
            <div class="item">
              <div class="label">结算方式</div>
              <div class="value">
                {{ renderSettlementType(allianceInfo.settlementType) }}
              </div>
            </div>
            <div class="item">
              <div class="label">发票类型</div>
              <div class="value">
                {{ renderInvoiceType(allianceInfo.invoiceType) }}
              </div>
            </div>
            <div class="item">
              <div class="label">账期</div>
              <div class="value">{{ allianceInfo.accountPeriod }}月</div>
            </div>
            <div class="item">
              <div class="label">对账日</div>
              <div class="value">{{ allianceInfo.reconciliationDay }}日</div>
            </div>
            <div class="item">
              <div class="label">库存展示</div>
              <div class="value">
                {{ allianceInfo.stockShowName }}
              </div>
            </div>
          </div>
          <div class="btn mt-16 text-right" v-if="isOwner">
            <el-button size="small" plain @click="handleDissolve"
              >解散联盟</el-button
            >
            <el-button size="small" plain @click="handOver"
              >移交管理员</el-button
            >
            <el-button size="small" plain @click="handleInvite"
              >邀请友商</el-button
            >
            <el-button size="small" plain @click="handleManage"
              >联盟规范管理</el-button
            >
          </div>
          <div class="btn mt-16 text-right" v-else>
            <el-button size="small" plain @click="handleExit">
              退出联盟
            </el-button>
          </div>
        </div>
      </div>
      <div class="flex-1 mt-8">
        <el-tabs type="card" v-model="activeTab" @tab-click="changeTab">
          <el-tab-pane
            v-for="(tab, index) in tabs"
            :key="index"
            :label="tab.name"
            :name="tab.id"
          >
            <components
              v-if="activeTab === tab.id"
              :is="tab.id"
              :isOwner="isOwner"
              :allianceObj="allianceInfo"
              @tabChange="handlerTabChange"
            />
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>

    <!-- 邀请加盟 -->
    <InviteJoinBox
      ref="inviteJoin"
      :excludeAllianceId="excludeAllianceId"
      @sure="sureInvite"
    />
    <!-- 移交弹框 -->
    <TransAllianceAdminBox
      ref="transAllianceAdmin"
      :excludeAllianceId="excludeAllianceId"
      @sure="sureTransAlliance"
    />
    <!-- 解散、退出弹框 -->
    <TipsBox ref="tips" :tipsObj="tipsObj" @sure="handleSure" />
    <!-- 联盟规范 -->
    <AllianceNormBox
      ref="allianceNorm"
      :allianceObj="allianceInfo"
      @sure="allianceNormSure"
    />
  </div>
</template>

<script>
import InviteJoinBox from "../components/InviteJoinBox.vue";
import TransAllianceAdminBox from "../components/TransAllianceAdminBox.vue";
import TipsBox from "../components/TipsBox.vue";
import AllianceNormBox from "../components/AllianceNormBox.vue";

import MemberList from "./memberList/index.vue";
import AllianceNorm from "./allianceNorm/index.vue";
import AllianceLogs from "./allianceLogs/index.vue";

import { TabChangeMixin } from "@/mixins/tabChangeMixin.js";
import {
  getAllianceDetail, //联盟详情
  deleteAlliance, //解散联盟
  inviteAlliance, //邀请加盟
  transferAlliance, //移交盟主
  updateAlliance, //更新联盟
  quitAllianceMembers, //退出联盟
} from "@/api/alliance";
import { getDicts } from "@/api/commonService";

export default {
  name: "AllianceDetail",
  mixins: [TabChangeMixin],
  components: {
    InviteJoinBox,
    TransAllianceAdminBox,
    TipsBox,
    AllianceNormBox,
    MemberList,
    AllianceNorm,
    AllianceLogs,
  },
  data() {
    return {
      allianceInfo: {},
      settlementTypeList: [],
      invoiceTypeList: [],
      tipsObj: {},
      activeTab: "MemberList",
      defaultTab: "MemberList",
      isOwner: true,
    };
  },
  computed: {
    excludeAllianceId() {
      return Number(this.$route.query.id);
    },
    tabs() {
      let list = [];
      let memberCount = this.allianceInfo.memberCount;
      if (this.isOwner) {
        //是管理员
        list = [
          { id: "MemberList", name: `成员列表(${memberCount || 0})` },
          { id: "AllianceNorm", name: "联盟规范" },
          { id: "AllianceLogs", name: "联盟日志" },
        ];
      } else {
        list = [
          { id: "MemberList", name: `成员列表(${memberCount || 0})` },
          { id: "AllianceNorm", name: "联盟规范" },
        ];
      }
      return list;
    },
  },
  created() {
    this.getPaymentTypeList(); //结算方式
    this.getInvoiceTypeList(); //发票类型
    // this.getDetail();
  },
  mounted() {
    this.getDetail();
  },
  methods: {
    //确认邀请
    sureInvite(val) {
      let params = {
        id: this.excludeAllianceId,
        companyIds: val,
      };
      inviteAlliance(params).then(() => {
        this.$message.success("邀请成功");
        this.$refs.inviteJoin.close();
        this.getDetail();
      });
    },
    //确认移交
    sureTransAlliance(memberId) {
      let params = {
        allianceId: this.excludeAllianceId,
        memberId,
      };
      transferAlliance(params).then(() => {
        this.$message.success("移交成功");
        this.$refs.transAllianceAdmin.close();
        this.getDetail();
      });
    },
    //联盟规范确认
    allianceNormSure(val) {
      let {
        id,
        name,
        areaId,
        settlementType,
        invoiceType,
        reconciliationDay,
        accountPeriod,
        stockShow,
        priceType,
        institution,
        introduction,
        logoUrl,
      } = val;
      let params = {
        id,
        name,
        areaId,
        settlementType,
        invoiceType,
        reconciliationDay,
        accountPeriod,
        stockShow,
        priceType,
        institution,
        introduction,
        logoUrl,
      };
      updateAlliance(params).then(() => {
        this.$message.success("更新联盟成功");
        this.$refs.allianceNorm.close();
        this.getDetail();
      });
    },
    getPaymentTypeList() {
      let params = {
        type: "settlementType",
      };
      getDicts(params)
        .then((res) => {
          this.settlementTypeList = res ? res : [];
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getInvoiceTypeList() {
      let params = {
        type: "invoiceType",
      };
      getDicts(params)
        .then((res) => {
          this.invoiceTypeList = res ? res : [];
        })
        .catch((err) => {
          console.log(err);
        });
    },
    renderSettlementType(settle) {
      let str = "";
      this.settlementTypeList.forEach((p) => {
        if (settle == p.code) {
          str = p.name;
        }
      });
      return str;
    },
    renderInvoiceType(invoice) {
      let str = "";
      this.invoiceTypeList.forEach((p) => {
        if (invoice == p.code) {
          str = p.name;
        }
      });
      return str;
    },
    getDetail() {
      let params = {
        id: this.$route.query.id,
      };
      getAllianceDetail(params).then((res) => {
        this.allianceInfo = res || {};
        this.isOwner = res.isOwner;
      });
    },
    //联盟规范管理
    handleManage() {
      this.$refs.allianceNorm.show();
    },
    //邀请
    handleInvite() {
      this.$refs.inviteJoin.show();
    },
    //移交
    handOver() {
      this.$refs.transAllianceAdmin.show();
    },
    //解散
    handleDissolve() {
      this.tipsObj = {
        title: "解散提示",
        type: 1,
        showIcon: true,
        tipsHead: "是否确认解散选中的联盟？",
        tipsContent: `是否确认解散“${this.allianceInfo.name}”联盟，解散成功后联盟成员将不能在此联盟进行商品询价、交易等操作，请谨慎操作！`,
      };
      this.$refs.tips.show();
    },
    //退出联盟
    handleExit() {
      this.tipsObj = {
        title: "提示",
        type: 2,
        showIcon: false,
        tipsHead: "是否确认退出该联盟？",
        tipsContent: `是否确认退出该联盟，退出成功后将不能在该联盟进行商品询价、交易等操作，请谨慎操作！`,
      };
      this.$refs.tips.show();
    },
    handleSure(type) {
      if (type == 1) {
        //确认解散联盟
        deleteAlliance({ id: this.excludeAllianceId }).then(() => {
          this.$message.success("解散成功");
          this.$refs.tips.close();
          this.$router.push("/allianceManage/list");
        });
      } else if (type == 2) {
        //确认退出联盟
        quitAllianceMembers({ id: this.excludeAllianceId }).then(() => {
          this.$message.success("退出联盟成功");
          this.$refs.tips.close();
          this.$router.push("/allianceManage/list");
        });
      }
    },
    handlerTabChange(tabName) {
      this.activeTab = tabName;
    },
  },
};
</script>

<style lang="less" scoped>
.detail {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
  .head {
    padding: 10px;
    font-size: 16px;
    font-weight: bold;
    background: #eeeeee;
    border-bottom: 1px solid #e9e9e9;
  }
  .content {
    flex: 1;
    // overflow: auto;
    .logo {
      width: 114px;
      height: 114px;
      border-radius: 50%;
      border: 1px solid #c6c6c6;
      background: #f8f8f8;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 50%;
      }
    }
    .info {
      width: 250px;
    }
    .item {
      display: flex;
      flex-direction: column;
      flex: 1;
      text-align: center;
      .label {
        font-size: 14px;
        color: #999999;
      }
      .value {
        font-size: 24px;
        font-weight: bold;
        margin-top: 8px;
      }
    }
    .el-tabs {
      height: 100%;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      /deep/ .el-tabs__header {
        margin: 0;
      }
      /deep/ .el-tabs__content {
        flex: 1;
        padding: @padding-size-main;
        .el-tab-pane {
          height: 100%;
          min-height: 400px;
        }
      }
    }
  }
}
</style>
